<template>
  <!--  我参与的 -->
  <div class="init" ref="init">
    <div class="init-title" v-if="requiresList.length > 0">
      <p style="width: 260px; text-indent: 2em">Project</p>
      <p style="width: 230px">Progress</p>
      <p style="width: 230px">Base allocation</p>
      <p style="width: 118px; text-indent: 2em">state</p>
      <p style="width: 48px"></p>
    </div>
    <div class="attention" v-if="requiresList.length > 0">
      <div
        class="list-box bettwen cur"
        v-for="item in requiresList"
        :key="item.id"
        @click="unfold(item.id)"
      >
        <div class="BitHotel">
          <img :src="item.logo" alt="logo" />
          <p>{{ item.name }}</p>
        </div>

        <!--  -->
        <div class="flex ionbox">
          <p style="font-size: 14px; color: #8a97c1">
            {{ item.fundraiseProgress }} /
          </p>
          &nbsp;
          <p style="color: #21e2a9; font-size: 14px">
            {{ item.fundraiseGoal }} {{ item.outputToken }}
          </p>
        </div>
        <div class="flex ionbox">
          <p style="font-size: 14px; color: #8a97c1">Base allocation:</p>
          &nbsp;
          <p style="color: #21e2a9; font-size: 14px">
            ${{ item.baseAllocation }}
          </p>
        </div>

        <div class="WhiteList">{{ Distribution(item) }}</div>

        <div class="receive" v-if="item.unclaimed > 0" @click="unfold(item.id)">
          {{ fn_unclaimed(item.unclaimed) }} {{ item.outputToken }} unclaimed
          <i class="el-icon-arrow-right"></i>
        </div>

        <img
          v-else
          @click="unfold(item.id)"
          class="enter-into cur"
          style="width: 48px; height: 48px"
          src="../../static/images/qieh.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
    <el-empty :image-size="200" v-if="nodata" description=" ">
      <template #image>
        <svg-icon iconClass="empty" class="empty-icon"></svg-icon>
      </template>
    </el-empty>
  </div>
</template>

<script>
import { idomine } from "@/api/controller";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      requiresList: [],
      nodata: false,
      loading: "",
    };
  },
  created() {
    this.requires();
  },

  mounted() {
    addEventListener("scroll", this.handleScroll, true);
  },

  methods: {
    fn_unclaimed(e) {
      try {
        return Number(e).toFixed(3);
      } catch (error) {
        console.log(error);
      }
    },

    Distribution(item) {
      if (item.idoState == "SellComplete" || item.idoState == "Extract") {
        return "Distribution";
      } else if (item.idoState == "Lottery") {
        if (new Date().getTime() >= new Date(item.lotteryDrawTime).getTime()) {
          // 已到公布时间
          return "Lottery";
        } else {
          return "WhiteList";
        }
      } else if ((item.idoState = "Selling")) {
        // 销售开始
        if (new Date().getTime() >= new Date(item.sellStartTime).getTime()) {
          return "IDO";
        } else {
          return "Lottery";
        }
      } else {
        return item.idoState;
      }
    },

    unfold(id) {
      this.$router.push({ path: "/newdetails", query: { id } });
    },

    handleScroll() {
      let offsetHeight = document.querySelector(".el-main").offsetHeight; //窗口可视高度
      let innerHeight = document.querySelector(".attention").offsetHeight; //元素的高度
      let scrollTop = document.querySelector(".el-main").scrollTop; //滚动条滚动距离
      // 55 == el-main的padding等
      let bottomOfWindow = scrollTop + (offsetHeight - 55) === innerHeight;
      if (bottomOfWindow) {
        //用户下拉触底
        setTimeout(() => {
          this.pageSize += 1;
          this.requires();
        }, 200);
      }
    },

    async requires() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await idomine({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      });
      // console.log(res,'我参与的IDO项目')
      if (res.code === 0) {
        this.loading.close();
        if (res.data.list.length > 0) {
          this.requiresList.push(...res.data.list);
        } else {
          this.nodata = true;
        }
      } else {
        this.loading.close();

        this.nodata = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.init-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px 0 43px;
  color: #fefefe;
  margin-bottom: 15px;
  font-weight: bold;
}
.attention {
  .receive {
    margin-left: 20px;
    font-size: 14px;
    color: #1edfa6;
  }
  .list-box {
    margin-bottom: 15px;
    height: 104px;
    background: #20225b;
    border-radius: 30px;
    padding: 0 17px 0 43px;
    .ionbox {
      width: 230px;
    }
    .WhiteList {
      width: 118px;
      height: 37px;
      background: rgba(#1edfa6, 0.1);
      border-radius: 19px;
      font-size: 14px;
      color: #1edfa6;
      text-align: center;
      line-height: 37px;
    }
    .BitHotel {
      width: 260px;
      font-size: 16px;
      font-weight: bold;
      color: #fefefe;
      display: flex;
      align-items: center;
      p {
        width: 184px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        width: 56px;
        height: 56px;
        background: #30326e;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }
}
</style>
